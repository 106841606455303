.cbox-3 {
  position: relative;
  
  background-size: cover;
  background-repeat: no-repeat; 
  background-position: center;
  height: 100% !important; /* Add this property to make sure the container takes up the full height of the viewport */
}

.background-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  opacity: 0.5;
}

.iphone-image {
  position: relative;
  z-index: 1;

}