.App {
	text-align: center;
}

.App-logo {
	height: 40vmin;
	pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		animation: App-logo-spin infinite 20s linear;
	}
}

.App-header {
	background-color: #282c34;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.App-link {
	color: #61dafb;
}

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

/* Form CSS*/

@media screen and (max-width: 600px) {
	.dynamicColumn {
		width: 100%;
		box-sizing: border-box;
	}
}

.sign-up-form-layout {
	height: 60px;
	background-color: #f8f9fa;
	border: 1px solid #e5e5e5;
	color: #333;
	font-size: 17px;
	margin-bottom: 20px;
	padding: 5px 20px;
	border-radius: 4px;
	transition: all 300ms ease-in-out;
	/*Spacing between elements in form */
	margin-left: 1%;
	margin-right: 1%;
}


/* Blog Details CSS */	

.post-inner-img .img-fluid {
  width: 90%;
  height: auto;
  object-fit: contain;
}

@media (max-width: 600px) {
  .post-inner-img .img-fluid {
    height: 300px;
  }
}

@media (min-width: 601px) and (max-width: 1024px) {
  .post-inner-img .img-fluid {
    height: 400px;
  }
}

@media (min-width: 1025px) {
  .post-inner-img .img-fluid {
    height: 585px;
  }
}